*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-size: 62.5%;
  font-family: 'Kanit', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin-bottom: 0;
}
